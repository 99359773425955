import React, { Fragment, useEffect, useState } from 'react'
import { Panel, Placeholder, Row, Col } from 'rsuite';
import profileImage from '../../assets/profile.png';
import salaryImage from '../../assets/salary.png'
import eResourcesImage from '../../assets/e-resources.png';
import leaveImage from '../../assets/leave.png'
import wifiImage from '../../assets/wi-fi.png'
import VehicalPassImage from '../../assets/pass.png'
import allowanceImage from '../../assets/allowance.png'
import insuranceImage from '../../assets/insurance.png'
import adminImage from '../../assets/admin.png'
import hrms from '../../assets/hrms.png'
import { Image } from 'react-bootstrap';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Configurations } from '../../config';

const CardContainer = styled`
  	display: flex;
    flex-direction: column;
`;


export default function CardListComponent({initialData,isLoading}) {

   const [isHovered, setIsHovered] = useState(false);
   const [panelId, setPanelId] = useState(0);
   const navigate = useNavigate();
const [accessItemList, setaccessItemList] = useState([]);

useEffect(()=>{
  if(initialData.length !== 0){
    const sorted = initialData?.accessItems?.sort((a, b) => a.priorityNo - b.priorityNo);
    setaccessItemList(sorted);
  }

},[initialData]);


  // Function to handle hover state
  const handleMouseEnter = (id=0) => {
    setIsHovered(true);
    setPanelId(id)
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

const Card = ({props, cardImageURL ,cardImage=null,title="",pId=0,navigateLocation="/home"}) => (

  <Panel onClick={()=>{navigate(navigateLocation);}} onMouseEnter={()=>handleMouseEnter(pId)} onMouseLeave={handleMouseLeave} shaded {...props} bordered style={isHovered && panelId === pId ? { backgroundColor: '#f0f0f0', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',minWidth:"150px",cursor: 'pointer' } : {minWidth:"150px"}} className='mt-2 '>
    {/* <Placeholder.Paragraph /> */}
    <Row style={{ display: "flex",flexDirection: 'column',alignItems: 'center',justifyContent: 'center' }}>
        <img src={cardImageURL} alt="logo" width={40} height={40} />
        <p style={isHovered && panelId === pId ? {fontSize:"14px",color: "#990000"}:{fontSize:"12px"}} className='mt-1'>{title}</p>
    </Row>
     
  </Panel>
);

const Link = ({props, cardImageURL ,cardImage=null,title="",pId=0,navigateLocation}) => (

  <a href={navigateLocation} target='_blank' style={{ textDecoration: 'none' }}>
  <Panel onMouseEnter={()=>handleMouseEnter(pId)} onMouseLeave={handleMouseLeave} shaded {...props} bordered style={isHovered && panelId === pId ? { backgroundColor: '#f0f0f0', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',minWidth:"150px",cursor: 'pointer',textDecoration: 'none' } : {minWidth:"150px"}} className='mt-2 '>
    {/* <Placeholder.Paragraph /> */}
    <Row style={{ display: "flex",flexDirection: 'column',alignItems: 'center',justifyContent: 'center' }}>
        <img src={cardImageURL} alt="logo" width={40} height={40} />
        <p style={isHovered && panelId === pId ? {fontSize:"14px",color: "#990000"}:{fontSize:"12px"}} className='mt-1'>{title}</p>
    </Row>
     
  </Panel>
  </a>
);


  return (
    <Fragment>
        <Row >
      {!isLoading && (
        <>
        {accessItemList.map((val,index)=>(
          <>
          {val?.ServiceType === 0 && (
              <Col  md={6} sm={12}>
                <Card cardImageURL={Configurations.baseUrl+val?.imageURL} pId={index} title={val?.name} navigateLocation={val?.navigate}/>
              </Col>
          )}
          
          {val?.ServiceType === 2 && (
              <Col  md={6} sm={12}>
                <Link cardImageURL={Configurations.baseUrl+val?.imageURL} pId={index} title={val?.name} navigateLocation={val?.navigate}/>
              </Col>
          )}
          </>
          
        ))}
        </>
      )}
        

    {/* {initialData?.accessItems?.myProfile && (
        <Col  md={6} sm={12}>
          <Card cardImage={profileImage} pId={1} title='My Profile' navigateLocation="/"/>
        </Col>
    )}

    {initialData?.accessItems?.mySalary && (
       <Col md={6} sm={12}>
      <Card cardImage={salaryImage} pId={2} title='My Salary' />
    </Col>
    )}

    {initialData?.accessItems?.eResources && (
        <Col md={6} sm={12}>
      <Card cardImage={eResourcesImage} pId={3} title='E-Resources' />
    </Col>
    )}

    {initialData?.accessItems?.myLeave && (
        <Col md={6} sm={12}>
      <Card cardImage={leaveImage} pId={4} title='My Leave' />
    </Col>
    )}

    {initialData?.accessItems?.usjnetWifi && (
        <Col md={6} sm={12}>
      <Card cardImage={wifiImage} pId={5} title='USJNet Wi-Fi' />
    </Col>
    )}

    {initialData?.accessItems?.hrms && (
       <Col md={6} sm={12}>
      <Card cardImage={hrms} pId={6} title='HRMS' />
    </Col>
    )}

    {initialData?.accessItems?.vehicalPass && (
        <Col md={6} sm={12}>
      <Card cardImage={VehicalPassImage} pId={7} title='Vehicle Pass' />
    </Col>
    )}

    {initialData?.accessItems?.researchAllowance && (
        <Col md={6} sm={12}>
      <Card cardImage={allowanceImage} pId={8} title='Research Allowance' />
    </Col>
    )}

    {initialData?.accessItems?.insurance && (
        <Col md={6} sm={12}>
      <Card cardImage={insuranceImage} pId={9} title='Insurance' />
    </Col>
    )}

    {initialData?.accessItems?.usjnetAdmin && (
       <Col md={6} sm={12}>
      <Card cardImage={adminImage} pId={10} title='USJNet Admin' navigateLocation="/admin"/>
    </Col>
    )} */}


   
    
    
    
    
    
    
    
    
    
    
    
  </Row>
    </Fragment>
  )
}
