import React, { Fragment, useEffect } from 'react'
import LoginPageComponent from '../components/login-page-component/login-page-component'
import backgroundImage from '../assets/temp_usjnet_login.PNG'

export default function LoginPage() {

  const divStyle = {
    backgroundImage: `url(${backgroundImage})`, // Use the imported image
    height: '100vh',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  useEffect(()=>{
    window.location.href = `https://usjnetsso.sjp.ac.lk/login/user_login_sso`;
  },[])

  return (
   <Fragment>
     <div style={divStyle}>Loading...</div>
   </Fragment>
  )
}
