export const Configurations = {
    //baseUrl : "http://127.0.0.1:8000",
    baseUrl : "https://usjnetsso.sjp.ac.lk/sso",
    baseUrl2 : "https://usjnetsso.sjp.ac.lk",
    adminBaseUrl: "",
    primaryButtonColor : "#002746",
    secondaryButtonColor: "gray",
    primaryBGColer: "white",
    primarySelectedColor: "black",
    secondaryBGColor: "#002746",
    bannerHeaderColor: "#002746",
    bannerBouttomColor: "white",
};