import React, { Fragment } from 'react'
import AutoplaySlider from '../autoplay-slider'
import CardListComponent from '../card-list-component'
import LeftSideListComponent from '../left-side-list-component'
import SkeletonTypography from '../../common/skelton/skelton-one'
import SkeletonChildren from '../../common/skelton/skeleton-two'

export default function SectionHeaderCompnent({initialData,isLoading}) {
    console.log(initialData?.ApplicationList?.length)
  return (
    <Fragment>
        <section class="hero ">
        <div class="container">
            <div class="row">

                 <div className={(initialData?.ApplicationList?.length === 0 || initialData?.ApplicationList?.length === undefined) && !isLoading ? "col-lg-12 mt-4" : "col-lg-8 mt-4"}>
                    {isLoading ? (
                        <>
                        <SkeletonTypography />
                        </>
                        
                    ):(
                    <>
                    <AutoplaySlider />
                    <CardListComponent isLoading={isLoading} initialData={initialData} />
                    </>
                    )}
                    
                    
                </div>
                
                {isLoading && (
                    <div className="col-lg-4 mt-4">
                            <SkeletonChildren />
                    </div>
                )}

                {initialData?.ApplicationList?.length > 0 && !isLoading && (
                        <div className="col-lg-4 mt-4">
                        <div class="hero__categories">

                        <div class="section-title">
                            <h2>Applications</h2>
                        </div>
                            <ul>   
                                <LeftSideListComponent isLoading={isLoading} initialData={initialData}/>           
                            </ul>                        
                                            
    
                        </div>
                    </div>
                )}

               
               
            </div>
        </div>
    </section>
    </Fragment>
  )
}
