
import './App.css';
import AppRoute from './route/route';
import { Fragment } from 'react';
import 'rsuite/dist/rsuite.min.css';
import { ServiceContextProvider } from './services/services-context';

function App() {

  

  return (
    <Fragment>
      <ServiceContextProvider>
        <AppRoute />
      </ServiceContextProvider>
      
    </Fragment>
  );
}

export default App;
